<template>
    <div class="container">
        <h2 class="h2-spaced">Oops! That didn't work.</h2>
        <p v-if="!txt">Looks like we could not find the page you are looking for.</p>
        <p v-else>{{txt}}</p>
        <p>
          Would you like to go to the
          <router-link
              to="/products"
              title="Overview page"
          >
            Overview?
          </router-link>

        </p>

    </div>
</template>

<script>
    export default {
        name: "NotFound",
        // need to leave productData/itemsPerPage, since also passing them to router view
        props: ["txt", "productData", "itemsPerPage"]
    }
</script>

<style scoped>

.container {
    margin-bottom: 30em;
}
</style>