import { createRouter, createWebHistory } from 'vue-router';

import TheProducts from "./components/product/TheProducts";
import TheCategories from "./components/category/TheCategories";
import NotFound from "./components/navigation/NotFound";
import ThePrivacy from "./components/misc/ThePrivacy";
import TheTerms from "./components/misc/TheTerms";
import TheImpressum from "./components/misc/TheImpressum";

const fallbackMeta =  {
    title: 'Release Radar – product release cycles made transparent',
    description: "Your guide to understanding product release cycles. Which products have recently " +
    "been updated, when is the best time to buy, and where to learn more.",
    keywords: "released, announced, new iphone when, new kawai when, new yamaha when, release date kawai," +
    "release date yamaha, upcoming, new model"
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/products'
        },
        {
            name: 'categories',
            path: '/products',
            component: TheCategories,
            meta: fallbackMeta
        },
        {
            name: 'products',
            path: '/products/:chosenCategory',
            component: TheProducts,
            props: true,
            meta: {
                title: {
                    'digitalpianos': 'Digital Pianos | Latest & Upcoming Models | Your Release Radar',
                    'smartphones': 'Smartphones | Latest & Upcoming Models | Your Release Radar',
                    'gameconsoles': 'Game Consoles | Latest & Upcoming Models | Your Release Radar',
                    'cameras': 'Digital Cameras | Latest & Upcoming Models | Your Release Radar',
                    'fallback': fallbackMeta.title
                },
                description: {
                    'digitalpianos': "Your guide to digital piano releases. Which Yamaha and Kawai" +
                        "models have recently been updated? When is the best time to buy a Clavinova? Learn more " +
                        "about your favorite digital piano.",
                    'smartphones': "Your guide to understanding smartphone releases. Has the iPhone just " +
                        "been updated? When is the best time to buy a Samsung Galaxy. Learn more about your favorite " +
                        "smartphone.",
                    'gameconsoles': "Your guide to understanding game console releases. Has the PlayStation just " +
                        "been updated? When is the best time to buy a Xbox. Learn more about your favorite console.",
                    'cameras': 'Your guide to understanding camera releases. Has Canon just updated its lineup? ' +
                        'When is the best time to buy a new EOS R or Sony Alpha camera. Learn more about your ' +
                        'favorite camera.',
                    'fallback': fallbackMeta.description
                },
                keywords: {
                    'digitalpianos': "release, new kawai when, new yamaha when, clavinova, novus, " +
                        "release date kawai, release date yamaha, pianoworld",
                    'smartphones': "release, new iphone when, latest iphone, new galaxy when, " +
                        "latest galaxy, release date iphone, next pixel, next nokia, next iphone",
                    'gameconsoles': "release, new playstation when, new xbox when, new switch when, " +
                        "latest playstation, next playstation, latest xbox, next xbox, nintendo switch, ps5, ps6",
                    'cameras': "release, new EOS R5C when, new a7 IV when, new sony alpha 7 III when, " +
                        "latest canon, next canon eos, latest canon, next sony compact camera, camera streaming",
                    'fallback': fallbackMeta.keywords
                }
            }
        },
        {
            name: 'privacy',
            path: '/privacy',
            component: ThePrivacy,
            meta: fallbackMeta
        },
        {
            name: 'terms',
            path: '/terms',
            component: TheTerms,
            meta: fallbackMeta
        },
        {
            name: 'impressum',
            path: '/impressum',
            component: TheImpressum,
            meta: fallbackMeta
        },
        {
            name: 'notfound',
            path: "/:catchAll(.*)",
            component: NotFound,
            meta: fallbackMeta
        }
    ],
    scrollBehavior(to, from, savedPosition) {

        // Decide for which cases we want no scrolling
        //if (to.query.pg !== from.query.pg) return
        if (to.query.filter !== from.query.filter) return
        if (to.query.sort !== from.query.sort) return

        // jump to model if one is passed
        if (to.query.model) {
                return {
                    el: "#" + to.query.model
                }
            }
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash
            }
        } else {
            return {
                left: 0,
                top: 0
            }
        }
    }
});

router.beforeEach((to, from, next) => {

    // This callback runs before every route change, including on page load.
    // idea: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
    const nearestWithKeywords = to.matched.slice().reverse().find(r => r.meta && r.meta.description);

    let title, description, keywords
    let category = to.params.chosenCategory
    if (typeof category !== 'undefined') {
        if (!['smartphones', 'digitalpianos', 'gameconsoles', "cameras"].includes(category)) {
            category = 'fallback' // need to catch random user route input and error pages such as /next
        }
        title = to.meta.title[category];
        description = to.meta.description[category];
        keywords = to.meta.keywords[category];
    } else {
        title = nearestWithTitle.meta.title;
        description = nearestWithDescription.meta.description;
        keywords = nearestWithKeywords.meta.keywords;
    }

    // update doc
    document.title = title
    document.querySelector('meta[name="description"]').content = description
    document.querySelector('meta[name="keywords"]').content = keywords

    next();
});


router.afterEach(function(to) {
    // workaround since goatcounter is loading async and window.goatcounter might not be defined yet,
    // since exported from goatcounter script.
    var t = setInterval(function() {
        if (!window.goatcounter || !window.goatcounter.count)
            return

        clearInterval(t)
        // propagate route
        window.goatcounter.count({
            path: to.fullPath
        })
    }, 100)
});


export default router;