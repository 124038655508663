<template>
  <div class="row" :id="product.id">
    <div class="col-4">
      <div class="text-center">
        <picture loading="lazy">
          <source :srcset="'/assets/img/category/' + category + '/' + product['image'].split('.').at(0) + '.webp'" type="image/webp">
          <source :srcset="'/assets/img/category/' + category + '/' + product['image']" type="image/jpeg">
        <img
          class="img-fluid w-100"
          :src="'/assets/img/category/' + category + '/' + product['image']"
          :alt="product.company + ' ' + product.model"
          :title="product.company + ' ' + product.model"
        />
        </picture>
      </div>
    </div>
    <div class="col-8 text-start">
      <div class="row">
        <div class="col-12">
          <h3>{{ product.company }} {{ product.model }}</h3>
        </div>
        <div class="col-12 mb-2">
          <base-badge
              :sentiment="product.sentiment"
              type="double"
          >
          </base-badge>
        </div>
        <div class="col-12 d-none d-md-block">
          <div>
            {{ product.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-start d-md-none">
      <div>
        {{ product.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheDescription",
  props: ["product", "category"]
};
</script>

<style scoped>

  img {
    width: 100%;
  }

  h3 {
    font-size: 2rem;
  }

  @media (max-width: 1000px) {
    h3 {
      font-size: 1.5rem;
    }
  }
</style>
