<template>
  <footer class="footer-custom mt-auto text-start">
    <hr/>
    <div class="container-lg">
      <div class="row">
        <div class="col-md-4">
          <h3>
              ReleaseRadar
          </h3>
          <p class="pe-4">
            Your guide to understanding release cycles. Which products have
            recently been updated, when is the best time to buy, and where to
            learn more.
          </p>
        </div>
        <div class="col-md-4">
          <h3>Products</h3>
          <ul class="list-unstyled nav-links">
            <li>
              <router-link
                  to="/products"
                  title="Overview page"
              >
                Overview
              </router-link>
            </li>
            <li>
              <router-link
                  :to="setCategory('digitalpianos')"
                  title="digital piano releases"
              >
                Digital pianos
              </router-link>
            </li>
            <li>
              <router-link
                  :to="setCategory('smartphones')"
                  title="smartphone releases"
              >
                Smartphones
              </router-link>
            </li>
            <li>
              <router-link
                  :to="setCategory('gameconsoles')"
                  title="game console releases"
              >
                Game consoles
              </router-link>
            </li>
            <li>
              <router-link
                  :to="setCategory('cameras')"
                  title="camera releases"
              >
                Cameras
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-4">
          <h3>Legal</h3>
          <ul class="list-unstyled nav-links">
            <li>
              <router-link
                :to="{ name: 'privacy' }"
                title="How we use your data"
              >
                Privacy Policy
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'terms' }"
                title="The rules under which we provide this service"
              >
                Terms &amp; Conditions
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'impressum' }"
                title="Details required by German law"
              >
                Legal Notice
              </router-link>
            </li>
            <li><a href="mailto:contact@thereleaseradar.com">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 text-center">
          <div class="copyright mt-5 pt-1">
            <p>
              <small>
                &copy; 2022 All Rights Reserved.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  methods: {
    setCategory(chosenCategory) {
      return {
        name: "products",
        params: {
          chosenCategory: chosenCategory
        }
      };
    }
  }
};
</script>

<style scoped>
  .footer-custom {
    padding: 0;
    background-size: cover;
    background-color: rgb(220,219,221);
    color: rgb(70,70,70);
    box-shadow: 0 50vh 0 50vh rgb(220,219,221);
  }

  .footer-custom h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 0.7rem;
  }

  .footer-custom .nav-links li {
    margin-bottom: 10px;
  }

  .footer-custom .nav-links li a {
    color: rgb(70,70,70);
  }

  .footer-custom .nav-links li a:hover {
    color: black;
  }

  .footer-custom .copyright {
    border-top: 1px solid #595959;
  }

  .row {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  hr {
    margin-top: 0;
  }
</style>