<template>
    <span v-if="type==='single'">
      <span class="badge" :style="btnColor(1)">{{ sentiment }}</span>
    </span>
    <span v-if="type==='double'">
      <span class="badge-1 badge" :style="btnColor(1)">{{ sentiment }}</span>
      <span class="badge-2 badge" :style="btnColor(0.2)">{{btnText}}</span>
    </span>
</template>

<script>
export default {
  name: "BaseBadge",
  props: ["sentiment", "type"],
  computed: {
    btnText: function () {
      let txt;
      const s = this.sentiment;
      if (s === "Don't buy") {
        txt = "Await Successor";
      } else if (s === "Caution") {
        txt = "Mid-cycle";
      } else if (s === "Buy now") {
        txt = "Just Updated";
      } else if (s === "Neutral") {
        txt = "No Predecessors";
      }
      return txt
    },
  },
  methods: {
    btnColor: function(alpha) {
      let col;
      const s = this.sentiment;
      if (s === "Don't buy") {
        col = "rgba(202, 68, 74," +  alpha + ")";
      } else if (s === "Caution") {
        col = "rgba(245, 195, 68," +  alpha + ")";
      } else if (s === "Buy now") {
        col = "rgba(64, 133, 88," +  alpha + ")";
      } else if (s === "Neutral") {
        col = "rgba(110, 117, 124," +  alpha + ")";
      }
      return "background-color:" + col
    }
  }
};
</script>

<style scoped>

.badge-1 {
  border-radius: 0.25rem 0 0 0.25rem;
}

.badge-2 {
  border-radius: 0 0.25rem 0.25rem 0;
  color: black;
  box-shadow:inset 0 0 0 0.5px gray;
  font-weight: 400;
}

</style>
