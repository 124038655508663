<template>
  <div class="container">
    <h2 class="h2-spaced">Choose Category</h2>
    <p class="text-start">
      Pick the category for which you want to see the latest releases and model generation history.
      <span class="d-none d-md-inline">
        We focus on a small selection of product lines only: gaming consoles from Sony, Nintendo and Microsoft,
        Keyboards from Yamaha and Kawai, as well as smartphones from Nokia, Apple, OnePlus, Samsung and Google.
        For all of them, we track the history of releases and examine the product release cycle, so you always know
        which generation is the latest and when to expect the next release.
      </span>
      <a class="d-inline" href="#footer-description">Read more</a>.
    </p>

    <base-card>
      <div class="row g-0">
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('smartphones')" title="Smartphones Category">
            <base-image
              path='/assets/img/categories/smartphones.jpg'
              alt='Smartphones'
              title="Smartphones"
            >
            </base-image>
          </router-link>
        </div>
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('digitalpianos')" title="Digital Pianos Category">
            <base-image
                        path='/assets/img/categories/digitalpianos.jpg'
                        alt='Digital Pianos'
                        title="Digital Pianos"
            >
            </base-image>
          </router-link>
        </div>
        <div class="col-4 g-0 d-none d-md-block">
          <router-link class="custom-border" :to="setCategory('next')" title="Request based Category">
            <img
                class="img-fluid"
                src="/assets/img/categories/missing.svg"
                alt="TBD"
                title="Request Based"
            />
          </router-link>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('gameconsoles')" title="Gaming Consoles Category">
            <base-image
                path='/assets/img/categories/gameconsoles.jpg'
                alt='Gaming Consoles'
                title="Gaming Consoles"
            >
            </base-image>
          </router-link>
        </div>
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('next')" title="Request based Category">
            <img
              class="img-fluid"
              src="/assets/img/categories/missing.svg"
              alt="TBD"
              title="Request Based"
            />
          </router-link>
        </div>
        <div class="col-4 g-0 d-none d-md-block">
          <router-link class="custom-border" :to="setCategory('next')" title="Request based Category">
            <img
              class="img-fluid"
              src="/assets/img/categories/missing.svg"
              alt="TBD"
              title="Request Based"
            />
          </router-link>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('next')" title="Request based Category">
            <img
              class="img-fluid"
              src="/assets/img/categories/missing.svg"
              alt="TBD"
              title="Request Based"
            />
          </router-link>
        </div>
        <div class="col-6 col-md-4 g-0">
          <router-link class="custom-border" :to="setCategory('cameras')" title="Camera Category">
            <img
              class="img-fluid"
              src="/assets/img/categories/cameras.jpeg"
              alt="Cameras"
              title="Cameras"
            />
          </router-link>
        </div>
        <div class="col-4 g-0 d-none d-md-block">
          <router-link class="custom-border" :to="setCategory('next')" title="Request based Category">
            <img
              class="img-fluid"
              src="/assets/img/categories/missing.svg"
              alt="TBD"
              title="Request Based"
            />
          </router-link>
        </div>
      </div>
    </base-card>
  </div>

  <!-- custom text section -->
  <footer-text
     chosenCategory="generic"
  >
  </footer-text>
</template>

<script>
import BaseImage from "../UI/BaseImage";
import FooterText from "../misc/FooterText";
export default {
  name: "TheCategories",
  components: {BaseImage, FooterText},
  methods: {
    setCategory(chosenCategory) {
      return {
        name: "products",
        params: {
          chosenCategory: chosenCategory
        }
      };
    }
  }
};
</script>

<style scoped>
img {
  height: 200px;
  object-fit: cover;
  margin: auto;
  padding: 20px;
}
img:hover {
  opacity: 0.7;
}
.custom-border {
  border-color: gray;
  border-style: solid;
  border-width: 1px;
  display: flex;
}
</style>
