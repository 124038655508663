<template>
    <svg class="barChart" :viewBox="viewBox">
        <g>
            <!-- x-axis -->
            <g class="x-axis" fill="none" :transform="`translate(0, ${height})`">
                <path :d="`M0.5,6V0.5H${width}`"></path>
                <g class="tick" text-anchor="end" v-for="(bar, index) in bars" :key="index"
                    :transform="`translate(${bar.x + bar.width / 2}, 0)`">
                    <line stroke="black" y2="6"></line>
                    <text id="xlabel" y="9" dy="0.7em" transform="translate(0,0)rotate(-45)">
                        {{ bar.xLabel }}
                    </text>
                </g>
                <text class="x label" :y="-height + 15" :x="-40">
                    days
                </text>
            </g>

            <!-- y-axis -->
            <g class="y-axis" fill="none" :transform="`translate(0, 0)`">
                <path class="domain" :d="`M0.5,${height}.5H0.5V0`"></path>
                <g class="tick" text-anchor="end" v-for="(tick, index) in yTicks" :key="index"
                   :transform="`translate(0, ${y(tick) + 0.5})`">
                    <line x2="-6"></line>
                    <text id="ylabel" x="-9" dy="0.32em">
                        {{ tick }}
                    </text>
                </g>
            </g>

            <!-- bars -->
            <g class="bars">
                <rect
                        v-for="(bar, index) in bars"
                        :key="index"
                        :height="bar.height"
                        :fill="barColor(index)"
                        :width="bar.width"
                        :x="bar.x"
                        :y="bar.y"
                ></rect>
            </g>

            <!-- hline -->
            <g class="hline">
                <line stroke-width="1.3" x1="0" :x2="width" :y1="lineHeight" :y2="lineHeight"></line>
                <rect height="20" width="60" :x="width-60" :y="lineHeight-21"></rect>
                <text :y="lineHeight-6" :x="width-60">
                    Average
                </text>
            </g>

        </g>
    </svg>
</template>

<script>
    // use for prototyping
    // import * as d3 from 'd3';

    // use for prod
    import { scaleBand, scaleLinear } from "d3-scale";
    import { max } from "d3-array";

    export default {
        name: "BarChart",
        scaleBand: scaleBand,
        scaleLinear: scaleLinear,
        max: max,
        props: {
            releases: {
                required: true,
                type: Array
            },
            sentiment: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                width: 300,
                height: 170
            };
        },
        methods: {
            barColor(ix) {
                let barCol;
                if (ix!==this.releases.length-1) {
                    barCol="rgb(130,130,130)"
                } else {
                    const s = this.sentiment;
                    if (s === "Don't buy") {
                        barCol = "#dc3545"
                    } else if (s === "Caution") {
                        barCol = "#ffc107"
                    } else if (s === "Buy now") {
                        barCol = "#198754"
                    } else if (s === "Neutral") {
                        barCol = "#6c757d"
                    }
                }
                return barCol
            }
        },
        computed: {
            lineHeight() {
                return this.y(this.releases[0].average);
            },
            yTicks() {
                return this.y.ticks(5).slice(0, -1); // remove highest to leave space for label
            },
            viewBox() {
                return `-45 -10 ${this.width+50} ${this.height+100}`;
            },
            x() {
                return scaleBand()
                    .range([0, this.width])
                    .padding(0.2)
                    .domain(this.releases.map(function(d) { return d.model; }));
            },
            y() {
                return scaleLinear()
                    .range([this.height, 0])
                    .domain([0, max(this.releases, d => d.days * 1.5)]);
            },
            bars() {
                let bars = this.releases.map(d => {
                    return {
                        xLabel: d.model,
                        x: this.x(d.model),
                        y: this.y(d.days), // why does passing an argument here make a difference?
                        width: this.x.bandwidth(),
                        height: this.height - this.y(d.days)
                    };
                });
                return bars;
            }

        },
    };
</script>


<style scoped>
    .barChart {
        margin: 0 0 0 0;
        padding-bottom: 2rem;
        display: inline-block;
        width: 90%;
    }

    .hline rect {
        fill: rgba(255,255,255,0.4)
    }

    .hline text {
        fill: black
    }

    path, line {
        stroke: black
    }

    text {
        fill: rgb(50, 50, 50);
        font-weight: 300;
        font-family: "Avenir", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #xlabel, #ylabel {
      font-size: 0.8rem;
    }
</style>