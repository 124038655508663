<template>
  <div
    class="row justify-content-center text-start mt-3"
    id="footer-description"
  >
    <hr />
    <div class="container-lg">
      <div class="col-12 mt-3">
        <h3 v-if="['smartphones', 'digitalpianos', 'cameras', 'generic'].includes(chosenCategory)">
          Just the Right Product
        </h3>

        <p v-if="chosenCategory === 'smartphones'">
          We focus on a small selection of smartphone manufacturers only:
          Samsung, OnePlus, Apple, Google and Nokia. For all of them, we examine
          the product release cycle. This way you will always know which
          generation is the newest, and when to expect the next release. In case
          you believe an additional manufacturer should be added to this, feel
          free to reach out.
        </p>

        <p v-if="chosenCategory === 'digitalpianos'">
          We focus on a small selection of digital piano manufacturers only:
          Yamaha, Kawai and Casio. For all of them, we examine the release cycle
          of their keyboards. This way you will always know which generation is
          the newest, and when to expect the next release. In case you believe
          an additional manufacturer should be added to this, feel free to reach
          out.
        </p>

        <p v-if="chosenCategory === 'cameras'">
          We focus on a small selection of camera manufacturers only:
          Canon and Sony. For all of them, we examine the release cycle
          of their bodies. This way you will always know which generation is
          the newest, and when to expect the next release. In case you believe
          an additional manufacturer should be added to this, feel free to reach
          out.
        </p>

        <div v-if="chosenCategory === 'gameconsoles'">
          <h3>Just the Right Video Game Consoles</h3>

          <p>
            We focus on a small selection of gaming console manufacturers only:
            Sony, Nintendo and Microsoft. Those three divide most of the market amongst each other.
            For all of them, we examine the product release cycle based on past releases to identify when to
            expect upcoming models. All generations are listed in a history chart, next to an estimate for the next
            release. This way you can quickly deduce when the next Xbox can
            be expected, or when this years PlayStation release should be. Further, you will see new consoles
            at first glance and be able to tell which model is the latest. Our release
            radar tracks your favorite gaming console along with all its releases. All to help you understand when to
            best buy your next game console - and when not to.
          </p>

          <h3>The Advent of Video Gaming</h3>
          <p>
            The first consoles emerged already around 1970. Back then they could only run games that were built into the
            hardware and were heavily limited by processing power, memory and screen resolutions. From the now dominant
            consoles, the oldest predecessor is the NES from Nintendo, which dates back to 1985. The first Sony
            PlayStation was released in November 1995 and it wasn't until 2001 that Microsoft released the first Xbox.
            Since then game consoles have come a long way, with major refreshes taking place every 5-6 years. Still, the
            basic concept of a video game console being a dedicated gaming computer still holds true.
            As such, they usually come with a controller instead of mouse and keyboard and
            are purpose built to deliver high quality graphics and audio for a reasonable price tag.
            Be it single or multiplayer, they are built for gaming and entertainment. The latest generations are now
            also integrating online connectivity, allowing owners to stream movies and access social media
            in between playing their favourite games.

          </p>
          <h3> The latest Generation of Games Consoles</h3>
          <p>
            Gaming consoles are released in so called generations, each lasting several years. The latest
            generation is the so-called 9th Generation of gaming consoles, featuring the PlayStation 5 (PS 5), the Xbox
            Series S and the Xbox Series X, which replaced their predecessors, the Xbox One S (All-Digital Edition),
            the Xbox One X, as well as the PlayStation 4 Pro (PS 4 Pro). The Nintendo Switch (which replaced the Wii U)
            is as of now still an eighth generation console. When buying one of the consoles, it might be worthwhile to
            also dive into which games run on each of the platforms, since many run exclusively on only one of the
            systems. For example, while the Xbox and Playstation models excel at shooters and driving
            games, the Switch is targeting the casual gamer, or families who look for games such as Mario Kart, Animal
            Crossing, Pokemon Legends, or Mario Party. The Nintendo Switch can further, be used as a mobile console,
            when taken out of its docking station.
          </p>

          <h3>Beyond the Horizon: The Next Generation of Gaming Consoles</h3>
          <p>
            So what will the upcoming game consoles for 2022 and beyond look like?
            As Nintendo's Switch is still an eighth generation product, we will likely see it to
            experience a major redesign soon. Its last update was rather a cosmetic change. Both Xbox consoles are still
            early to mid cycle, meaning they will likely not experience a major refresh for some time. Similarly for
            Sony's PlayStation, which has just passed the halfway mark of its average refresh timing, and it might still
            take some time until the latest PlayStation is replaced with the PlayStation 6.
          </p>

        </div>

        <p v-if="chosenCategory === 'generic'">
          We focus on a small selection of products only: smartphones, digital pianos
          and gaming consoles. For all of them we cover the manufacturers with the greatest market share. E.g., for
          video game consoles we focus on Sony, Nintendo and Microsoft and dive into the latest generation of
          Xbox Series S and Series X consoles, the PlayStation 5 and the Nintendo Switch.
          Similarly for digital pianos, where we examine when the newest Clavinova models from Yamaha
          came out, or when the Novus 5, Novus 10 and Kawai CA models were released. We further estimate
          when the next generation – such as the PlayStation 6 – is to be expected. All to help you
          understand when to best buy your next game console, or home piano - and when not to.
          The list of categories and the set of companies covered will continuously be extended. In case you
          believe an additional manufacturer should be added to a category, feel free to reach out.
        </p>
      </div>
      <div class="col-12 mt-3">
        <h3 v-if="['smartphones', 'digitalpianos', 'cameras', 'generic'].includes(chosenCategory)">How to Know When to Buy</h3>

        <p v-if="chosenCategory === 'smartphones'">
          We help you make informed purchases. Be it the next iPhone, Galaxy, or
          OnePlus Nord device. Our release radar tracks your favorite phone
          along with all its releases. All recent smartphone releases are
          further shown in a timeline. Finally, we estimate when to expect a
          product successor. All to help you understand when to best buy your
          next mobile phone - and when not to.
        </p>

        <p v-if="chosenCategory === 'digitalpianos'">
          We help you make informed purchases. Be it the next Kawai Novus
          series, or the next Clavinova or AvantGrand piano from Yamaha. Our
          release radar tracks your favorite keyboard along with all its
          releases. All recent releases are shown in a timeline. Finally, we
          estimate when to expect a product successor. All to help you
          understand when to best buy your next digital or hybrid piano - and
          when not to.
        </p>

        <p v-if="chosenCategory === 'cameras'">
          We help you make informed purchases. Be it the next Sony Alpha
          model, or the next Canon EOS R or EOS M camera. Our
          release radar tracks your favorite camera along with all its
          releases. All recent releases are shown in a timeline. Finally, we
          estimate when to expect a product successor. All to help you
          understand when to best buy your next photo camera - and
          when not to.
        </p>

        <p v-if="chosenCategory === 'generic'">
          We help you make informed purchases. Be it a smartphone like the iPhone 13, a digital
          piano like the Yamaha P-515, or a gaming console like the Nintendo Switch. Our release radar
          tracks your favorite product along with all its releases. All recent releases are shown in
          a timeline and frequently updated, so you always know which model is the latest smartphone, the latest
          game console, or the latest stage piano. Finally, we estimate when to expect
          a product successor. All to help you make an informed decision as to when to buy.
        </p>
      </div>
      <div class="col-12 mt-3">
        <h3>Transparency for You</h3>
        <p>
          If you buy an item after clicking on one of the (*) marked affiliate
          links, you won't pay a penny more, but we'll earn some coffee money,
          which we promise to drink while creating more helpful content and
          extending this page. Disclaimer: The information on this page has been
          prepared with utmost care and we do our best to keep it up to date and
          relevant. Still, we cannot guarantee accuracy and completeness.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterText",
  props: ["chosenCategory"]
};
</script>

<style scoped>
.row {
  background-color: rgb(246, 245, 247);
}

hr {
  margin-bottom: 1rem;
}

p,
h3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

h3 {
  font-size: 1.5rem;
}

.container-lg {
  padding-bottom: 2rem;
}
</style>
