<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-10 text-start">
        <span id="filter">Filter:</span>
        <router-link v-for="(company, index) in uniqueCompanies"
                     :key="index"
                     :to="filterFor(company)"
                     :title="'Show only ' + company"
        >
          <span
              @click="resetPageNumber()"
              class="badge rounded-pill"
              :class="{'bg-secondary': isActive(company), 'bg-light text-dark': !isActive(company)}"
          >
            {{company}}
          </span>
        </router-link>
      </div>

      <!-- Class show is needed here, since bootstrap will dynamically add it to dropdown later.
    If the class is not present somewhere in the code, vue will prune the css class at build time,
    thus the dropout will not show, since the css class is not present in prod.-->
      <div class="col-3 col-md-2 text-end show">
        <div class="dropdown d-none d-md-inline">
          <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            Sort By
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <router-link :to="changePageTo('sentiment')" class="dropdown-item">
                Recommendation
              </router-link>
            </li>
            <li>
              <router-link :to="changePageTo('model')" class="dropdown-item">
                Product Name
              </router-link>
            </li>
            <li>
              <router-link :to="changePageTo('company')" class="dropdown-item">
                Manufacturer
              </router-link>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductFiltering",
  computed: {
    ...mapGetters(["uniqueCompanies"]),
    chosenSorting() {
      return this.$route.query.sort;
    }
  },
  emits: ["page-change"],
  methods: {
    isActive(company) {
      return this.$route.query.filter === company
    },
    resetPageNumber() {
      this.$store.commit("setPage", 1);
    },
    filterFor(target) {
      let q;
      if (this.$route.query.filter===target) {
        // reset value
        q = {...this.$route.query, model: undefined, filter: undefined}
      } else {
        q = {...this.$route.query, model: undefined, filter: target}
      }
      return { query: q }
    },
    changePageTo(target) {
      return {
        query: {
          sort: target
        }
      };
    },
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

 span {
   margin-right: 1rem;
   margin-top: 0.5rem;
   font-size: 1rem;
 }

 #filter {
   padding-bottom: 1rem;
 }

</style>