<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-start">
        <h2>{{ adaptiveHeading(chosenSorting) }}</h2>
        <p v-if="category==='Smartphones'">
          We focus on a small selection of smartphone manufacturers only: Samsung, OnePlus, Apple, Google and Nokia.
          For all of them, we track the history of releases and examine the product release cycle.
          <span class="d-none d-md-inline">
            This way you will always know which generation is the newest, and when to expect the next release.
          </span>
          <a href="#footer-description">Read more</a>
        </p>
        <p v-if="category==='Digitalpianos'">
          We focus on a small selection of digital piano manufacturers only: Yamaha & Kawai. For both,
          we track the history of releases and examine the release cycle of their keyboards.
          <span class="d-none d-md-inline">
            This way you will always know which generation is the newest, and when to expect the next release.
          </span>
          <a href="#footer-description">Read more</a>
        </p>
        <p v-if="category==='Gameconsoles'">
          We focus on a small selection of gaming console manufacturers only: Sony, Nintendo and Microsoft.
          For all of them, we track the history of releases and examine the product release cycle.
          <span class="d-none d-md-inline">
            This way you will always know which generation is the newest, and when to expect the next release.
          </span>
          <a href="#footer-description">Read more</a>
        </p>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "HeaderText",
  props: ["category"],
  computed: {
    chosenSorting() {
      return this.$route.query.sort;
    }
  },
  methods: {
    adaptiveHeading(chosen) {
      if (typeof chosen === "undefined") {
        chosen = this.$store.getters.initialSorting;
      }
      const category = this.category;
      if (chosen === "sentiment") {
        return "Recently released " + category;
      } else if (chosen === "model") {
        return category + " sorted by Name";
      } else {
        return category + " sorted by Manufacturer";
      }
    }
  }
};
</script>

<style scoped>

hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

button {
  margin-bottom: 1rem;
}

@media (max-width: 800px) {
  h2 {
    font-size: 1.5rem;
  }
}
</style>
