import App from "./App.vue";
import router from "./router.js";
import store from "./store";
import { createApp } from "vue";

import BaseCard from "./components/UI/BaseCard.vue";
import BaseBadge from "./components/UI/BaseBadge.vue";

import "bootstrap/dist/css/bootstrap.min.css";
// use only what is needed, otherwise just run import "bootstrap"
import "bootstrap/js/dist/dropdown.js"

const app = createApp(App);

app.component("base-card", BaseCard);
app.component("base-badge", BaseBadge);

app.use(router);

app.use(store);

app.mount("#app");
