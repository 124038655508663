<template>
    <div class="row justify-content-center text-start mb-4">
        <div class="col-11 col-md-8">
            <h2 class="h2-spaced"><strong>Legal Notice</strong></h2>

            <p>
                The following information (Impressum) is required under German law.
            </p>

          <p>
                releaseradar<br />
                David Dillmann<br />
                Kaiserdamm 88<br />
                14057 Berlin<br />
          </p>
          <h3>
            <strong>Contact</strong>
          </h3>
          <p>
                david@thereleaseradar.com<br />
                +49 176 433 81 333<br />
                https://thereleaseradar.com
          </p>
          <h3>
            <strong>Content responsibility in line with § 55 Abs. 2 RStV:</strong>
          </h3>
          <p>
            The contents of these pages were prepared with utmost care.
            Nonetheless, we cannot assume liability for the timeless accuracy
            and completeness of the information. Responsible for the content of releaseradar is David Dillmann,
            Kaiserdamm 88, 14057 Berlin.
          </p>

                <!--
                Es sollte noch die Wirtschafts-Identifikationsnummer (W-IdNr) nach § 139c dazu
              Schnelle Kontaktmöglichkeit (E-Mail, Telefonnr. oder evtl. Kontaktformular)
              Ggf. Firmenname, Rechtsform und Vertretungsberechtigter
              Bei Eintrag in ein (z.B. Handels-, Vereins-, Genossenschafts-)Register: Registername, Ort, Registernr.
              UStID oder Wirtschafts-Identifikationsnr.
              Bei gesetzlich reglementierten Berufen: Gesetzl. Berufsbezeichnung, zuständige Kammer, Staat, von dem die
              Berufsbezeichnung verliehen wurde
              Ggf. Aufsichtsbehörde
              Bei journalistischen/redaktionellen Angeboten: Verantwortlicher im Sinne des Presserechts (V.i.S.d.P.)
              -->

            <h3>
                <strong
                >Online Dispute Resolution website of the EU Commission</strong
                >
            </h3>

            <p>
                In order for consumers and traders to resolve a dispute
                out-of-court, the European Commission developed the Online Dispute
                Resolution Website: www.ec.europa.eu/consumers/odr
            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "TheImpressum"
    }
</script>

<style scoped>

</style>