<template>
  <div class="row">
    <div class="col-4 text-start">
      Last release
    </div>
    <div class="col-8">
      <p class="text-start squeezed">
        {{ product["releases"][0]["days"] }} days ago
      </p>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-4 text-start">
      Next release
    </div>
    <div class="col-8">
      <p v-if="product['releases'][0]['average']" class="text-start squeezed">
        {{
          product["releases"][0]["average"] - product["releases"][0]["days"]
        }}
        days (estimated)
      </p>
      <p v-else class="text-start squeezed">
        Unknown
      </p>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-4 text-start">
      Recent releases
    </div>
    <div class="col-8 text-start">
      <div v-if="product['releases'].length > 1">
        <p v-if="isHidden" class="spaced">
          <a @click="isHidden = !isHidden ; logClick(product.model + ' history')"
             title="Show Product History">
            Show History
          </a>
        </p>
        <bar-chart
          v-else
          :releases="chartData(product)"
          :sentiment="product.sentiment"
        ></bar-chart>
      </div>
      <div v-else>
        No prior releases
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "./BarChart";
export default {
  name: "TheHistory",
  components: { BarChart },
  data() {
    return {
      isHidden: true
    };
  },
  props: ["product"],
  methods: {
    chartData: function(product) {
      let maxItems = 5;
      return product["releases"].slice(0, maxItems).reverse();
    },
    logClick(customPath) {
      if (typeof(window.goatcounter) != "undefined") {
        // logging is not blocked
        window.goatcounter.count({
          path: customPath,
          event: true
        })
      } else {
        console.log("Action not logged")
      }
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: underline !important;
  cursor: pointer;
}

a:hover {
  color: black !important;
}

.squeezed {
  margin-bottom: 0;
}

.spaced {
  margin-bottom: 0.4rem;
}

p.text-start {
  margin-bottom: 0;
}
</style>
