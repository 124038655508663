<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb container">
      <li class="breadcrumb-item">
        <router-link :to="categoryOverview" title="Home">Home</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ category }}
      </li>
    </ol>
  </nav>
  <hr/>
</template>

<script>
export default {
  name: "TheBreadcrumb",
  props: ["category"],
  computed: {
    categoryOverview() {
      return {
        name: "categories"
      };
    }
  }
};
</script>

<style scoped>

nav {
  --bs-breadcrumb-divider: ">";
  background-color: rgb(246,245,247);
  height: 50px;
  line-height: 50px;
  padding-left: 1rem;
  margin-bottom: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 2rem;
}

.breadcrumb a {
  color: black;
  text-decoration: none;
}

.breadcrumb >.active {
  color: rgba(0,0,0,0.5);
}

.breadcrumb a:hover {
  text-decoration: underline;
}

</style>
