<template>
  <div class="col-2">
    <router-link :to="jumpToModel(id)" :title="'Jump to ' + model">
      <picture loading="lazy">
        <source :srcset="'/assets/img/category/' + category + '/' + image.split('.').at(0) + '.webp'" type="image/webp">
        <source :srcset="'/assets/img/category/' + category + '/' + image" type="image/jpeg">
      <img
        class="img-fluid mx-auto d-block"
        :src="'/assets/img/category/' + category + '/' + image"
        :alt="model"
        :title="'Jump to ' + model"
      />
      </picture>
    </router-link>
    <div class="text-center text-adjust">
        <base-badge
            :sentiment="sentiment"
            type="single"
        > </base-badge>
      <p>{{ company }} {{ model }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseItem",
  props: {
    id: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    company: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    sentiment: {
      type: String,
      required: true
      // validator: function(value) {
      //     Could check if only allowed strings are passed
      // }
    },
    category: {
      type: String,
      required: true
    }
  },
  methods: {
    jumpToModel(target) {
      return {
        query: { ...this.$route.query, model: target }
      };
    }
  }
};
</script>

<style scoped>

  img {
    min-width: 100%;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.5em;        /* fallback */
    max-height: 4.5em;       /* fallback */
    min-height: 4.5em;
  }

  img:hover {
    opacity: 0.7;
  }

</style>