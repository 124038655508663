<template>
    <base-card>
      <div
        class="col-8 prod-desc"
      >
        <div class="row">
          <div class="col-12">
            <the-description
                    :product="product"
                    :category="category"
            >
            </the-description>
          </div>
          <div class="col-12 d-none d-md-block">
            <hr />
            <the-history :product="product"> </the-history>
          </div>
        </div>
      </div>

      <div class="col-4 bg-light text-start">
        <learn-more :product="product"> </learn-more>
      </div>
      <div class="col-12 prod-desc-small d-md-none">
        <hr class="hr-small" />
        <the-history :product="product"> </the-history>
      </div>
    </base-card>
</template>

<script>
import TheDescription from "./TheDescription";
import LearnMore from "./LearnMore";
import TheHistory from "./TheHistory";

export default {
  name: "ProductCard",
  components: { TheHistory, LearnMore, TheDescription },
  props: ["product", "category"],
  methods: {}
};
</script>

<style scoped>

.prod-desc {
  padding: 1rem;
}

.prod-desc-small {
  padding: 0 1rem 1rem 1rem;
}

.hr-small {
  margin-top: 0;
}
</style>
