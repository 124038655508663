<template>
  <div id="app">
    <!-- content. -->
    <!-- Note: Need key to refresh for loading data everytime category changes, but the component does not
     change. Otherwise switching from smartphones to pianos does not reload data-->
    <router-view :key="this.$route.params.chosenCategory"> </router-view>

    <!-- footer -->
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import TheFooter from "./components/misc/TheFooter";
export default {
  name: "app",
  components: { TheFooter }
};
</script>

<style>
#app {
  font-family: "Avenir", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
