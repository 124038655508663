import axios from "axios";

export default {
    async fetchData(context, payload) {
        // todo: add catch routines to account for computational errors, server errors, empty responses etc
        // todo: add loading screen in case the data is not available yet/error messages in other cases

        const fp = "/assets/json/";

        if (['smartphones', 'digitalpianos', 'gameconsoles', 'cameras'].includes(payload.category)) {

            const {data} = await axios.get(fp + payload.category + ".json");
            context.commit("setData", data);

            // preprocess
            for (let productId = 0; productId < data.length; productId++) {
                context.commit("addDaysBetweenReleases", productId);
                context.commit("addAverageDaysBetweenReleases", productId);
                context.commit("addSentiment", productId);
            }
        } else {
            console.log('no data found for', payload.category)
        }
    }
}