<template>
  <div class="row">
    <div class="col-6 text-start">
      <router-link id="scroll-up" :to="scrollUp()">
        &#8673; Scroll to top
      </router-link>
    </div>
    <div class="col-6">
      <nav aria-label="Page navigation">
        <ul
            v-if="numberItemsCategory(chosenFilter()) > numberItemsPage"
            class="pagination justify-content-end"
        >
          <li class="page-item" :class="isDisabledLeft(currentPage)">
            <a class="page-link" @click="changePageTo(currentPage - 1)">
              Previous
            </a>
          </li>
          <li
              v-for="page in Math.ceil(numberItemsCategory(chosenFilter()) / numberItemsPage)"
              :key="page"
              class="page-item d-none d-md-inline"
          >
            <a class="page-link" @click="changePageTo(page)">
              {{ page }}
            </a>
          </li>
          <li
              class="page-item"
              :class="isDisabledRight(currentPage, chosenFilter())"
          >
            <a class="page-link" @click="changePageTo(currentPage + 1)">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PageNumber",
  props: ["currentPage"],
  emits: ["page-change"],
  computed: {
    ...mapGetters(['numberItemsPage', 'numberItemsCategory']),
  },
  methods: {
    chosenFilter() {
      return this.$route.query.filter
    },
    isDisabledRight(page, filter) {
      return {disabled: page >= Math.ceil(this.numberItemsCategory(filter)/ this.numberItemsPage)}
    },
    isDisabledLeft(page) {
      return {disabled: page <= 1}
    },
    changePageTo(target) {
      this.$emit("page-change", target);
    },
    scrollUp() {
      return {
        query: { ...this.$route.query, model: undefined}
      }
    }
  }
};
</script>

<style scoped>

#scroll-up {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  color: rgb(100, 100, 100)
}

</style>