

export default {
    numberItemsPage(state) {
        return state.itemsPerPage;
    },
    productsOnPage: (state) => (currentPage, sortBy, filterBy) => {
        if (typeof sortBy === 'undefined') {
            sortBy = state.initialSorting
        }

        // sort
        let products = [...state.productData].sort((a,b) => {
            if (sortBy === "sentiment") {
                // cannot order by name, since neutral would be at end. Use custom order instead
                const order = { "Buy now": 1, "Neutral": 2, "Caution": 3, "Don't buy": 4 }
                return order[a[sortBy]] - order[b[sortBy]];
            } else {
                // sort by name
                if(a[sortBy].toLowerCase() > b[sortBy].toLowerCase()){return 1}
                if(a[sortBy].toLowerCase() < b[sortBy].toLowerCase()){return -1}
            }
            return 0
        });

        if (typeof filterBy !== 'undefined') {
            products = products.filter( i => filterBy.includes( i.company ))
        }
        const first = (currentPage - 1) * state.itemsPerPage;
        const last = currentPage * state.itemsPerPage;
        return products.slice(first, last)
    },
    numberItemsCategory: (state) => (filterBy) => {
        let length;
        if (typeof filterBy !== 'undefined') {
            length = state.productData.filter( i => filterBy.includes( i.company )).length
        }
        else {
            length = state.productData.length
        }
        return length
    },
    initialSorting(state) {
        return state.initialSorting;
    },
    uniqueCompanies(state) {
        let companies = state.productData.map(entry => entry.company)
        let unique_companies = [...new Set(companies)];
        return unique_companies
    },
    getPage(state) {
        return state.currentPage;
    },
}