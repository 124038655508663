<template>
  <div class="card">
    <div class="container-fluid">
      <div class="card-body">
        <div class="row">
          <slot> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard"
};
</script>

<style scoped>
.card {
  margin-bottom: 2em;
}
.card-body {
  padding: 0;
}
</style>