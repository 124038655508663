import {daysBetweenDates} from "@/utils";

export default {
    setData(state, data) {
        state.productData = data;
    },
    setPage(state, target) {
        state.currentPage = target;
    },
    addDaysBetweenReleases(state, id) {
        let product = state.productData[id];
        let oldDate;
        let newDate;
        for (let j = 0; j < product["releases"].length; j++) {
            if (j === 0) {
                oldDate = product["releases"][0].released;
                newDate = Date.now();
            } else {
                oldDate = product["releases"][j].released;
                newDate = product["releases"][j - 1].released;
            }
            product["releases"][j]["days"] = daysBetweenDates(oldDate, newDate);
        }
        state.productData[id] = product;
    },
    addAverageDaysBetweenReleases(state, id) {
        let product = state.productData[id];
        let differencesArray = product.releases.map(model => {
            return model.days;
        });
        differencesArray = differencesArray.slice(1); // exclude current model

        let avg;
        if (differencesArray.length === 0) {
            avg = ""; // no predecessor
        } else {
            avg = Math.floor(
                differencesArray.reduce((a, b) => a + b) / differencesArray.length
            );
        }
        product.releases.forEach(v => {
            v.average = avg;
        });
        state.productData[id] = product;
    },
    addSentiment(state, id) {
        let product = state.productData[id];
        let sentiment;
        if (product["releases"].length === 1) {
            sentiment = "Neutral"; // no predecessor
        } else {
            const average = product["releases"][0]["average"];
            const latest = product["releases"][0]["days"];
            if (latest > average) {
                sentiment = "Don't buy";
            } else if (latest <= average && latest > 0.5 * average) {
                sentiment = "Caution";
            } else {
                sentiment = "Buy now";
            }
        }
        product.sentiment = sentiment;
        state.productData[id] = product;
    }
}