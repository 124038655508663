<template>
    <picture class="mx-auto" loading="lazy">
        <source :srcset="path.split('.').at(0) + '.webp'" type="image/webp">
        <source :srcset="path" type="image/jpeg">
        <img
                class="img-fluid"
                :src="path.split('/').at(-1)"
                :alt="alt"
                :title="title"
        />
    </picture>
</template>

<script>
    export default {
        name: "BaseImage",
        props: ["path", "alt", "title"]
    }
</script>

<style scoped>
    img {
        height: 200px;
        object-fit: cover;
        padding: 20px;
    }
    img:hover {
        opacity: 0.7;
    }
</style>