<template>
  <h4>Learn More</h4>
  <ul class="list-unstyled nav-links mt-3">
    <li>
      <a
        :href="product['link_company']"
        target="_blank"
        title="Visit manufacturer"
        @click="logClick('company')"
      >
        <BIconBuilding />
        Visit product page
      </a>
    </li>
    <li>
      <a
          :href="product['link_review']"
          target="_blank"
          title="Find review"
          @click="logClick('review')"
      >
        <BIconYoutube />
        Find Youtube reviews
      </a>
    </li>
    <li>
      <a
          :href="product['link_shopping']"
          target="_blank"
          title="Buy online"
          @click="logClick('shopping')"
      >
        <BIconCart2 />
        Buy online&#42;
      </a>
    </li>
  </ul>
</template>

<script>
import { BIconCart2, BIconYoutube, BIconBuilding } from "bootstrap-icons-vue";

export default {
  components: {
    BIconCart2,
    BIconYoutube,
    BIconBuilding
  },
  name: "LearnMore",
  props: ["product"],
  methods: {
    logClick(linkType) {
        window.goatcounter.count({
          path:  this.$route.params.chosenCategory + " " + linkType,
          event: true
        })
    }
  }
};
</script>

<style scoped>
li {
  margin-top: 1rem;
}

a {
  font-size: 1.1rem;
}

h4 {
  font-size: 1.75rem;
  margin-top: 1rem;
}

@media (max-width: 1000px) {
  a {
    font-size: 1rem;
  }
  h4 {
    font-size: 1.3rem;
  }
}

.card .nav-links li a {
  color: rgb(70, 70, 70);
  text-decoration: none;
}

.card .nav-links li a:hover {
  color: black;
}
</style>
