import { createStore } from "vuex";

import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';

const store = createStore({
  state() {
    return {
      itemsPerPage: 5,
      productData: [],
      currentPage: 1,
      initialSorting: "sentiment" // company, sentiment, model
    };
  },
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters
});

export default store;
