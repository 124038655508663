<template>
  <div class="container ratio clsHeightCorrection">
    <div class="row d-flex align-items-center">
      <div class="col-1">
        <nav>
          <a
            class="arrow"
            v-if="showLeftOn(currentPage, chosenFilter())"
            @click="changePageTo(currentPage - 1)"
          >
            <strong> &lt; </strong>
          </a>
        </nav>
      </div>
      <base-item
        v-for="product in productsToDisplay"
        :key="product.id"
        :id="product.id"
        :model="product.model"
        :company="product['company']"
        :image="product.image"
        :sentiment="product.sentiment"
        :category="category"
      >
      </base-item>
      <div class="col-1">
        <nav>
          <a
            class="arrow"
            v-if="showRightOn(currentPage, chosenFilter())"
            @click="changePageTo(currentPage + 1)"
          >
            <strong> &gt; </strong>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import BaseItem from "../UI/BaseItem";
import { mapGetters } from 'vuex';

export default {
  name: "ProductNavigation",
  components: {
    BaseItem
  },
  props: [
    "productsToDisplay",
    "currentPage",
    "category"
  ],
  emits: ["page-change"],
  computed: {
    ...mapGetters(['numberItemsPage', 'numberItemsCategory']),
  },
  methods: {
    chosenFilter() {
      return this.$route.query.filter
    },
    showLeftOn(page, filter) {
      return page > 1 && this.numberItemsCategory(filter) > this.numberItemsPage * (page - 1);
    },
    showRightOn(page, filter) {
      return this.numberItemsCategory(filter) > this.numberItemsPage * page;
    },
    changePageTo(target) {
      this.$emit("page-change", target);
    }
  }
};
</script>

<style scoped>
.clsHeightCorrection {
  /* needed for less shift at page load */
  --bs-aspect-ratio: 28%;
}

.arrow {
  font-size: 2.5rem;
  color: rgb(150, 150, 150);
  text-decoration: none;
  margin-top: 4rem;
}

.arrow:hover {
  color: black;
}
</style>
