<template>
  <div v-if="['smartphones', 'digitalpianos', 'gameconsoles', 'cameras'].includes(chosenCategory)">
    <the-breadcrumb
        :category="capitalizeString(chosenCategory)"
    ></the-breadcrumb>

    <product-sorting
        :category="capitalizeString(chosenCategory)"
    ></product-sorting>

    <product-filtering
    @page-change="changePageTo">
    </product-filtering>

    <product-navigation
      :products-to-display="productsOnPage(currentPage, chosenSorting, chosenFiltering)"
      :current-page="currentPage"
      :category="chosenCategory"
      @page-change="changePageTo"
    ></product-navigation>

    <div class="container clsCorrection">
      <product-card
        v-for="product in productsOnPage(currentPage, chosenSorting, chosenFiltering)"
        :key="product.id"
        :product="product"
        :category="chosenCategory"
      ></product-card>

    <PageNumber
        :current-page="currentPage"
        @page-change="changePageTo"
    > </PageNumber>

    </div>
    <!-- custom text section -->
    <footer-text
        v-if="['smartphones', 'digitalpianos', 'gameconsoles', 'cameras'].includes(chosenCategory)"
        :chosenCategory="chosenCategory"
    ></footer-text>

  </div>
  <div v-else>
    <not-found
      txt="Unfortunately, we have not implemented anything for this category yet.
      If you want to see your product category to be covered next, please reach
      out and we will see what we can do!"
    ></not-found>
  </div>
</template>

<script>
import ProductCard from "./ProductCard";
import ProductNavigation from "../navigation/ProductNavigation";
import PageNumber from "../navigation/PageNumber";
import TheBreadcrumb from "../navigation/TheBreadcrumb";
import NotFound from "../navigation/NotFound";
import ProductSorting from "../misc/HeaderText";

import { mapActions, mapGetters } from "vuex";
import FooterText from "../misc/FooterText";
import ProductFiltering from "@/components/navigation/ProductFiltering";

export default {
  name: "TheProducts",
  components: {
    ProductFiltering,
    FooterText,
    NotFound,
    ProductCard,
    ProductNavigation,
    PageNumber,
    TheBreadcrumb,
    ProductSorting
  },
  props: ["chosenCategory"],
  beforeMount() {
    this.fetchData({ category: this.chosenCategory });
    this.changePageTo(1)
  },
  methods: {
    ...mapActions(["fetchData"]),
    capitalizeString(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    scrollFix: function() {
      // Helper to re-trigger router navigation which will then jump to the correct model without any hash left
      location.hash = "";
    },
    changePageTo(page) {
      this.$store.commit("setPage", page);
    },
  },
  mounted: function()
  {
    // Helper to trigger scrolling to the correct model after loading the page. Otherwise, the element is not present
    // yet, i.e. the top of the page is loaded. Watch out, this will require clicking the go back button in the browser
    // twice for navigation, since we reload the full page
    if (this.$route.query.model) {
      setTimeout(() => this.scrollFix(), 500);
    }
  },
  computed: {
    ...mapGetters(["productsOnPage"]),
    chosenSorting() {
      return this.$route.query.sort
    },
    chosenFiltering() {
      return this.$route.query.filter
    },
    currentPage() {
      return this.$store.state.currentPage
    }
    // currentPage() {
    //   const page = Number(this.$route.query.pg);
    //   if (Number.isNaN(page) || Number(page) <= 1) {
    //     // set default to have grey previous btn
    //     return 1;
    //   } else {
    //     return page;
    //   }
    // }
  }
};
</script>

<style scoped>
.clsCorrection {
  /* needed for less shift at page load - best value 2235px*/
  min-height: 500px;
}
</style>
